<template>
  <van-tabbar v-model="active" active-color="#ffa900" inactive-color="#333" :z-index="9" route>
    <van-tabbar-item v-for="(item, index) in tabbars" :key="index" replace :to="{ path: item.url, query: route.query }" @click="goToWy">
      <span>{{ item.title }}</span>
      <template #icon="props">
        <GlobalImage fit="contain" :src="props.active ? item.active : item.normal" height="20"></GlobalImage>
      </template>
    </van-tabbar-item>
  </van-tabbar>
</template>

<script setup>
import { ref, onBeforeMount, reactive } from 'vue-demi'
import { getImageUrl, isHideShowComment } from '@/utils'
import { store } from '@/pinia/store'
import { useRoute } from 'vue-router'

const route = useRoute()
const storeData = store()
const active = ref(0)
const tabbars = reactive([
  {
    title: '首页',
    normal: getImageUrl('public', 'home.png'),
    active: getImageUrl('public', 'home-active.png'),
    url: '/index',
  },
  {
    title: '技师',
    normal: getImageUrl('public', 'technician.png'),
    active: getImageUrl('public', 'technician-active.png'),
    url: '/technicianModule/technicianList/index',
  },
  {
    title: '发现',
    normal: getImageUrl('public', 'trends.png'),
    active: getImageUrl('public', 'trends-active.png'),
    url: '/trendsModule/trends/index',
  },
  {
    title: '订单',
    normal: getImageUrl('public', 'order.png'),
    active: getImageUrl('public', 'order-active.png'),
    url: '/orderModule/myOrders/index',
  },
  {
    title: '我的',
    normal: getImageUrl('public', 'my.png'),
    active: getImageUrl('public', 'my-active.png'),
    url: '/myModule/home/index',
  },
])

onBeforeMount(() => {
  if (isHideShowComment()) {
    tabbars.splice(2, 1)
  }
})

const goToWy = () => {
  if (active.value == 1) {
    storeData.umengOrder.origin = '技师列表'
  } else if (active.value == 2) {
    storeData.umengOrder.origin = '发现'
  } else if (active.value == 3) {
    storeData.umengOrder.origin = '我的订单'
  } else if (active.value == 4) {
    storeData.umengOrder.origin = '我的页面'
  }
}
</script>
